 .root, .App {
   height: 100vh;
   width: 100vw;
   background-color: white;
 }

 .image-list {
   height: 100%;
   width: 100%;
   margin: 0;
   background-color: black;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .imageContainer img {
   max-width: 100%;
   max-height: 100vh;
 }
 .imageContainer {
   position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  
 }

.disable {
  display: none;
}
.description {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  gap: 20px;
  padding: 20px;
}

.image-galery{
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-galery img{
  height: 200px;
  width: auto;
  margin: 0;
}
.button {
  color: azure;
  cursor: pointer;
}
.button-container {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 90%;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}
.button-container * {
  height: 50px;
  width: 50px;
  background-color:cadetblue;
  border-radius: 10px;
}
.button-container .button .icon {

  height: 30px;
  width: 30px;
  margin: 10px;
  mask-repeat: no-repeat;
 
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  background-color: black;
}
.button-container .button .icon.upload{
  mask-image: url(../assets/cloud.svg);
  -webkit-mask-image: url(../assets/cloud.svg);
}
.button-container .button .icon.fullscreen{
  mask-image: url(../assets/fullscreen.svg);
  -webkit-mask-image: url(../assets/fullscreen.svg);
}
.button-container .button .icon.download{
  mask-image: url(../assets/download.svg);
  -webkit-mask-image: url(../assets/download.svg);
}